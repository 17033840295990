import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hey there.`}</h1>
    <p>{`If you're here to learn how to start writing tests for your WordPress themes and plugins, you're in the right place. Click on `}<a parentName="p" {...{
        "href": "/get-started"
      }}>{`Get Started`}</a>{` up above and we'll jump right in.`}</p>
    <p>{`If you just want some copy/paste code snippets to help you write your tests faster, browse through some of our latest posts or see them all by clicking on `}<a parentName="p" {...{
        "href": "/posts"
      }}>{`Read all posts`}</a>{` down below.`}</p>
    <p>{`Search functionality is coming soon, but for now we're relying on Google to help us out. If there's a specific topic you're looking for, head over to `}<a parentName="p" {...{
        "href": "https://google.com"
      }}>{`Big G`}</a>{` and type in what you're looking for. Just make sure to type `}<inlineCode parentName="p">{`site:https://testingforwp.com`}</inlineCode>{` at the end and you'll get results from just this site. Pretty cool, huh?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      